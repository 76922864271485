var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { createModule, mutation, action, extractVuexModule, createProxy } from "vuex-class-component";
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);
const VuexModule = createModule({
    namespaced: "app",
    strict: false,
});
const json = require('../../../../api/data.json');
export class AppStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.boards = [];
        this.order = {};
        this.options = {};
        this.singleBoard = null;
        this.error = {
            show: false,
            state: '',
        };
    }
    setBoards(data) {
        this.boards = data;
    }
    setFilters(filters) {
        this.options = filters;
    }
    setSingleBoard(id) {
        this.singleBoard = this.boards.filter(el => el.id === id)[0];
        this.singleBoard.cover_type = 1;
        this.singleBoard.method_type = 1;
        this.singleBoard.selection_type = 1;
        this.singleBoard.count = 1;
    }
    updateSingleBoard(board) {
        this.singleBoard = board;
    }
    updateOrder(order) {
        this.order = order;
    }
    calculateFilters() {
        const board = this.singleBoard;
        const selection_type_price = this.singleBoard.options['selections'].find((el) => el.id === this.singleBoard.selection_type).price;
        const cover_type_price = this.singleBoard.options['coating_type'].find((el) => el.id === this.singleBoard.cover_type).price;
        const method_type_price = this.singleBoard.options['processing_method'].find((el) => el.id === this.singleBoard.method_type).price;
        this.order.totalPrice = (selection_type_price + cover_type_price + method_type_price) * board.count;
        this.order.order = {
            'Количество': this.singleBoard.count,
            'Cелекция': this.options['selections'].find((el) => el.id === this.singleBoard.selection_type).translate,
            'Способ обработки': this.options['processing_method'].find((el) => el.id === this.singleBoard.method_type).translate,
            'Тип покрытия': this.options['coating_type'].find((el) => el.id === this.singleBoard.cover_type).translate,
            'id': this.singleBoard.id
        };
    }
    setError(data) {
        this.error.state = data.state;
        this.error.show = data.show;
    }
    async fetchBoards() {
        await fetch('http://samvel2509.temp.swtest.ru/data.json').then((r) => {
            return r.json();
        }).then(data => {
            this.setBoards(data.data);
            this.setFilters(data.options);
        }).catch(e => {
            console.log(e);
            // this.setBoards(json.data)
            // this.setFilters(json.options)
        });
        // demo version is here
    }
    async createRequest() {
        // return await axios.post('/test_request', JSON.stringify(this.order)).then(r => {
        //     console.log(r)
        //     console.log('blblblb')
        // }).catch(e => {
        //     console.log(e)
        // })
    }
}
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "setBoards", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "setFilters", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "setSingleBoard", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "updateSingleBoard", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "updateOrder", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "calculateFilters", null);
__decorate([
    mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "setError", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppStore.prototype, "fetchBoards", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppStore.prototype, "createRequest", null);
// store.vuex.ts
export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(AppStore)
    }
});
// Creating proxies.
export const vxm = {
    app: createProxy(store, AppStore),
};
