var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import vSelect from 'vue-select';
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let VueMobileSelect = class VueMobileSelect extends Vue {
    constructor() {
        super(...arguments);
        this.mobile = false;
        this.selected = 1;
    }
    changeValue() {
        return this.selected.id;
    }
    mounted() {
        if (window.innerWidth <= 576) {
            this.mobile = true;
            this.selected = this.data[0];
        }
    }
};
__decorate([
    Prop(),
    __metadata("design:type", Array)
], VueMobileSelect.prototype, "data", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], VueMobileSelect.prototype, "filter", void 0);
__decorate([
    Emit('changeValue'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Number)
], VueMobileSelect.prototype, "changeValue", null);
VueMobileSelect = __decorate([
    Component({
        components: {
            vSelect
        }
    })
], VueMobileSelect);
export default VueMobileSelect;
