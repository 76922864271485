export default class Map {
	constructor(el) {
		this.map = el;
		this.mapGoogle = {

			map: '',
			imageMarker: '/images/map/pin.png',
			myPoint: new google.maps.LatLng(45.0502376, 38.9614596),
	
			options: {
				center: '',
				zoom: 17,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				disableDefaultUI: true,
				scrollwheel: false,
				styles: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}]
			}
		}
	}

	init() {
		console.log('map2');
		this.mapGoogle.options.center = this.mapGoogle.myPoint;
		this.addMap();
		this.addMarker();
	}

	addMap() {
		this.mapGoogle.map = new google.maps.Map(document.getElementById("map"), this.mapGoogle.options);
	}

	addMarker() {
		var markerCoordinates = this.mapGoogle.myPoint;
		var marker = new google.maps.Marker({
			position: markerCoordinates,
			map: this.mapGoogle.map,
			icon: this.mapGoogle.imageMarker,
		});
	}
}