<template lang="pug">
      TemplateCalc
</template>

<script>

  import TemplateCalc from './components/Template.vue'

  export default  {
    components: { TemplateCalc },
  }

</script>

<style lang="scss">
  @import "../../blocks/place-order-block/place-order-block.scss";
  @import "../../blocks/product-detail-block/product-detail-block.scss";
  @import "/node_modules/vue-select/src/scss/vue-select.scss";
</style>