var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { vxm } from '../store/store.tsx';
import FooterAdmin from '../components/FooterAdmin.vue';
import FooterClient from '../components/FooterClient.vue';
import FullBoards from '../components/FullBoards.vue';
import SingleBoard from '../components/SingleBoard.vue';
import SelectionType from '../components/Params/Selection.vue';
import BoardTitle from '../components/Params/Title.vue';
import MethodsType from '../components/Params/Methods.vue';
import CoversType from '../components/Params/CoverTypes.vue';
import Count from '../components/Params/Count.vue';
import ResultContainer from "./ResultContainer.vue";
let CalcProject = class CalcProject extends Vue {
    constructor() {
        super(...arguments);
        this.type = 'client';
        this.boards = null;
        this.singleBoard = null;
        this.options = null;
        this.totalPrice = 0;
        this.errorState = {};
        this.mobile = false;
    }
    created() {
        if (window.location.pathname === '/personal/make-order/') {
            this.type = 'admin';
        }
        else {
            if (document.cookie.split(';').filter((item) => item.trim().startsWith('BITRIX_SM_UIDL=')).length) {
                this.type = 'admin';
            }
            else {
                this.type = 'client';
            }
        }
    }
    async mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const idProduct = (urlParams.get('id')) ? urlParams.get('id') : 1;
        if (window.innerWidth <= 576) {
            this.mobile = true;
        }
        await vxm.app.$watch('singleBoard', payload => {
            this.singleBoard = vxm.app.singleBoard;
        });
        await vxm.app.$watch('options', payload => {
            this.options = payload;
        });
        await vxm.app.$watch("boards", payload => {
            this.boards = payload;
            vxm.app.setSingleBoard(Number(idProduct));
        });
        await vxm.app.$subscribe('calculateFilters', payload => {
            this.totalPrice = vxm.app.order.totalPrice;
        });
        await vxm.app.$watch('error', payload => {
            console.log(payload);
        });
        await vxm.app.fetchBoards();
        await vxm.app.calculateFilters();
    }
};
CalcProject = __decorate([
    Component({
        components: {
            FooterAdmin,
            FooterClient,
            FullBoards,
            SingleBoard,
            SelectionType,
            MethodsType,
            CoversType,
            Count,
            BoardTitle,
            ResultContainer
        }
    })
], CalcProject);
export default CalcProject;
