var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "result-container-action",
      class: { show: "result-container-action_show" }
    },
    [
      _c(
        "div",
        { staticClass: "request-form-container request-form-container_show" },
        [
          _c(
            "div",
            { staticClass: "request-form-container__content container" },
            [
              _vm._m(0),
              _c("div", { staticClass: "form-request-status" }, [
                _c("div", { staticClass: "form-request-status__icon" }, [
                  _vm.state === "error"
                    ? _c("img", {
                        attrs: { src: "/images/form/error-icon.svg" }
                      })
                    : _vm._e(),
                  _vm.state === "success"
                    ? _c("img", {
                        attrs: { src: "/images/form/success-icon.svg" }
                      })
                    : _vm._e()
                ]),
                _vm.state === "error"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "form-request-status__text form-request-status__text_error"
                      },
                      [
                        _vm._v(
                          "При отправке возникла ошибка, попробуйте еще раз"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.state === "success"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "form-request-status__text form-request-status__text_success"
                      },
                      [
                        _vm._v(
                          "Вы успешно оставили заявку, наш менеджер свяжется с вами в течении трех рабочих дней"
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "request-form-container__close-icon",
        attrs: { "data-result": "close" }
      },
      [_c("img", { attrs: { src: "/images/form/close-icon.svg" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }