var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "place-order-block single-board" }, [
    _c("div", { staticClass: "place-order-block__end full-width" }, [
      _c("div", { staticClass: "place-order-block__wrapper" }, [
        _c("img", { attrs: { src: _vm.board.images.full } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }