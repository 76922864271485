var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "place-order-block" }, [
    _c(
      "div",
      { staticClass: "place-order-block__param-row" },
      [
        _c("div", { staticClass: "place-order-block__param-title" }, [
          _vm._v("Тип покрытия")
        ]),
        !_vm.mobile
          ? _c(
              "div",
              { staticClass: "place-order-block__radio-wrapper" },
              _vm._l(_vm.data, function(item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "place-order-block__radio-item",
                    on: {
                      click: function($event) {
                        return _vm.setValue(item.id, item)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "place-order-block__param-label",
                        attrs: { for: "selection_item" + item.name }
                      },
                      [
                        _c("input", {
                          staticClass: "place-order-block__param-radiobutton",
                          attrs: {
                            type: "radio",
                            name: "cover_type",
                            id: "cover_item" + item.name
                          },
                          domProps: {
                            value: item.id,
                            checked:
                              _vm.singleBoard.cover_type === item.id
                                ? "checked"
                                : null
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "place-order-block__param-name" },
                          [_vm._v(_vm._s(item.translate))]
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c("VueMobileSelect", {
          attrs: { data: _vm.data },
          on: { changeValue: _vm.changeMobile }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }