import Forms from '../../../tools/plugins/forms.js'

export default class {

	constructor(el) {
		this.block = el;
		this.id = el.id;
		this.closeButton = el.querySelector('.modal__close');
		this.openButton = document.querySelector(`#${this.id}`);
	}

	init() {
		this.openButtonHandler();
		this.closeButtonHandler();
	}

	openButtonHandler() {
		this.openButton.addEventListener('click', (e) => {
			e.preventDefault();
			this.openModalHandler();
		})
	}

	showForm() {
		let close = true
		const formAll = document.querySelectorAll('form')
		const requestContainer = document.querySelectorAll('.request-form-container')
		if(document.querySelector('.page-title')) {
			document.querySelector('.page-title').style.display = ''
		}
		formAll.forEach(el => {
			if(el.style.display === 'none') {
				el.style.display = ''
				close = false
			}
			requestContainer.forEach(el => {
				el.classList.remove('request-form-container_show')
			})
		})
		return close
	}

	closeButtonHandler() {
		this.closeButton.addEventListener('click', (e) => {
			const closeNext = this.showForm()
			if(closeNext) {
				this.closeModalHandler();
			}
		})
	}

	openModalHandler() {
		this.block.classList.add('js_active');
		document.body.style.overflowY = 'hidden'
	}

	closeModalHandler() {
		document.body.style.overflowY = ''
		this.block.classList.remove('js_active');
	}
}