var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "place-order-block" }, [
    _c("div", { staticClass: "place-order-block__bottom" }, [
      _c("div", { staticClass: "place-order-block__start" }, [
        _c("div", { staticClass: "place-order-block__price-wrapper" }, [
          _c("div", { staticClass: "place-order-block__price-title" }, [
            _vm._v("Сумма:")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "place-order-block__calc-summ" }, [
            _vm._v(_vm._s(_vm.totalPrice) + " руб.")
          ])
        ])
      ]),
      _vm._m(0)
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "place-order-block__end" }, [
      _c("div", { staticClass: "place-order-block__button-wrapper" }, [
        _c("a", { staticClass: "button button_wide", attrs: { href: "#" } }, [
          _vm._v("Заключить договор")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "place-order-block__links" }, [
      _c("div", { staticClass: "place-order-block__previous" }, [
        _c(
          "a",
          {
            staticClass: "place-order-block__previous-link",
            attrs: { href: "#" }
          },
          [_vm._v("Перейти к прошлым заказам")]
        )
      ]),
      _c("div", { staticClass: "place-order-block__balances" }, [
        _c(
          "a",
          {
            staticClass: "place-order-block__balances-link",
            attrs: { href: "#" }
          },
          [_vm._v("Складские остатки")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }