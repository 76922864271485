var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "place-order-block" }, [
    _c("div", { staticClass: "place-order-block__bottom" }, [
      _c("div", { staticClass: "place-order-block__start c-p-result_client" }, [
        _c(
          "div",
          {
            staticClass:
              "place-order-block__price-title c-p-result_client__title"
          },
          [_vm._v("Сумма:")]
        ),
        _c(
          "div",
          {
            staticClass: "place-order-block__calc-summ c-p-result_client__summ"
          },
          [_vm._v(_vm._s(_vm.totalPrice) + " руб.")]
        )
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "place-order-block__end" }, [
      _c("a", { staticClass: "button button_wide", attrs: { href: "#" } }, [
        _vm._v("Оставить заявку")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }