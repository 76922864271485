import Plugin from "../core/Plugin";
import init from "../core/init";
import Inputmask from "inputmask";
import axios from "axios";
import validation from "../plugins/validation";

class Forms extends Plugin {
  defaults() {
    return {
      defaultError:
        "Что-то пошло не так. Попробуйте повториь запрос позднее или обратитесь к администратору сайта.",
      resultSelector: `[data-questions-left="result"]`,
      closeResultSelector: `[data-result="close"]`,
      titleSelector: `[data-questions-left="section-title"]`,
      phoneSelector: `[data-mask="phone"]`,
      formTargetId: "data-form-ymtarget-id",
      formTargetTitle: "data-form-gtag-title",
      formTargetCategory: "data-form-gtag-category",
      formTargetAction: "data-form-gtag-action",
      maskOptions: {
        // mask: '+7 (999) 999-99-99',
        regex: "^\\+7 \\([01234569][0-9]{2}\\) [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}$",
        placeholder: "+7 (___) ___-__-__",
        showMaskOnHover: false,
      },
      // baseUrl: settings.BASE_URL || CONFIG.BASE_URL,
      // contactUrl: settings.CONTACT_US || CONFIG.CONTACT_US
    };
  }

  init() {
    if (this.phoneField) this.initMask();
    this.element.instance = this;
  }

  buildCache() {
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideResult = this.hideResult.bind(this);
    this.form = this.element.querySelector("form");
    console.log(this.form)
    this.formTargetId = this.form.getAttribute(this.options.formTargetId);
    this.formTargetTitle = this.form.getAttribute(this.options.formTargetTitle);
    this.formTargetCategory = this.form.getAttribute(
      this.options.formTargetCategory
    );
    this.formTargetAction = this.form.getAttribute(
      this.options.formTargetAction
    );
    this.submit = this.form.querySelector('[type="submit"]');
    this.phoneField = this.form.querySelectorAll(this.options.phoneSelector);
    this.needResetForm = false;
    this.title = this.element.querySelector(this.options.titleSelector);
    this.resultContainer = this.element.querySelector(
      this.options.resultSelector
    );
    this.closeResult = document.querySelectorAll('.request-form-container__close-icon')
  }

  bindEvents() {
    if (this.form) {
      this.form.addEventListener("submit", (e) => this.handleSubmit(e));
    }
    if (this.closeResult) {
      this.closeResult.forEach(el => {
        el.addEventListener("click", this.hideResult);
      })
    }
  }

  initMask() {
    this.phoneMask = new Inputmask(this.options.maskOptions);
    this.phoneMask.mask(this.phoneField);
  }

  handleSubmit(e) {
    e.preventDefault();

    import("../plugins/validation").then((module) => {
      if (!this.validationModule)
        this.validationModule = module.default(this.form)[0];
      this.validationModule.validate().then(
        () => {
          this.showSubmitPreloader();
          this.sendForm().then(
            (data) => {
              this.hideSubmitPreloader();
              this.needResetForm = true;
              this.showResult(data.title, data.message, 'success');
              if (this.formTargetId)
                sendTargets(
                  this.formTargetId,
                  this.formTargetTitle,
                  this.formTargetCategory,
                  this.formTargetAction
                );
            },
            (error) => {
              this.hideSubmitPreloader();
              this.needResetForm = false;
              this.showResult("Ошибка", error, 'error');
            }
          );
        },
        () => {
          //Error validation
        }
      );
    });
    return false;
  }

  showSubmitPreloader() {
    console.log(this);
    this.submit.classList.add("button_loading");
    this.submit.setAttribute("disabled", "disabled");
  }

  hideSubmitPreloader() {
    this.submit.classList.remove("button_loading");
    this.submit.removeAttribute("disabled");
  }

  sendForm() {
    let _url = this.form.action;
    let _data = new FormData(this.form);
    return new Promise((resolve, reject) => {
      return axios
        .post(_url, _data)
        .then((response) => {
          //respone.data - html to replace
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message ? error.message : this.options.defaultError);
        });
    });
  }

  showResult(title, message, result) {
    let _title = this.resultContainer.querySelector('[data-result="title"]');
    let _message = this.resultContainer.querySelector(
      '[data-result="message"]'
    );

    // _title.innerText = title;
    // _message.innerText = message;

    if(result === 'success') {
      this.resultContainer.querySelector('.request-form-container_success').classList.add('request-form-container_show')
    }
    if(result === 'error') {
      this.resultContainer.querySelector('.request-form-container_error').classList.add('request-form-container_show')
    }

    if(this.element.querySelector('.page-title')) {
      this.element.querySelector('.page-title').style.display = 'none'
    }

    this.resultContainer.classList.add('request-result_show');
    // this.title.style.display = 'none'
    this.form.style.display = "none";

  }

  hideResult() {
    this.resultContainer.classList.remove('request-result_show')
    this.form.style.display = "";
    if(this.element.querySelector('.page-title')) {
      this.element.querySelector('.page-title').style.display = 'none'
    }
    if (this.needResetForm) {
      this.form.reset();
      let controls = this.form.querySelectorAll("input, textarea, select");
      controls.forEach((el) => {
        el.parentNode.classList.remove("input_filled");
      });
    this.events.emit("hideResult");
    // this.resultContainer.style.display = "none";
    // this.title.style.display = ''
  }
}
}

export default init(Forms, "form");
