var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mobile === true
    ? _c(
        "div",
        { staticClass: "mobile-select" },
        [
          _c("vSelect", {
            attrs: {
              options: _vm.data,
              label: "translate",
              inputId: "id",
              clearable: false,
              filterable: false,
              searchable: false,
              autoscroll: false
            },
            on: { input: _vm.changeValue },
            scopedSlots: _vm._u(
              [
                {
                  key: "open-indicator",
                  fn: function(ref) {
                    var attributes = ref.attributes
                    return [
                      _c("span", _vm._b({}, "span", attributes, false), [
                        _c("img", { attrs: { src: "/images/dropdown.svg" } })
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              961244804
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }