var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "place-order-block place-order-block_end board-multiple place-order-block__end"
    },
    [
      _c("div", { staticClass: "place-order-block__image-wrapper" }, [
        _c("div", {
          staticClass: "place-order-block__image",
          style: "background-image: url(" + _vm.singleBoard.images.full + ")"
        })
      ]),
      _c(
        "div",
        { staticClass: "place-order-block__goods-list" },
        [
          _vm._l(_vm.boards, function(item) {
            return _c("div")
          }),
          _vm._l(_vm.boards, function(item, index) {
            return _c("div", {
              key: item.id,
              staticClass: "place-order-block__goods-item",
              style: "background-image: url(" + item.images.small + ")",
              on: {
                click: function($event) {
                  return _vm.changeSingle(item.id)
                }
              }
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }