import "./assets/styles/main.scss";
import './assets/icons/icons';
import multiBrowser from "./assets/js/multiBrowser";
import common from './assets/js/common';

import Input from "./components/input/input"
import Menu from "./components/menu/menu";
import Accordion from "./components/accordion/accordion";
import Modal from "./components/modal/modal";
import ModalFormBlock from "./blocks/forms/modal-form-block";
import Map from "./blocks/map-block/map-block";
import ProductCalc from "./components/vue-calc/main.js";
import Forms from '../tools/plugins/forms.js'

import axios from 'axios';
window.axios = axios;
// smoothscroll.polyfill();

new common();
new multiBrowser();

if (document.querySelector('.menu')) {
    new Menu(document.querySelector('.menu')).init();
}

if (document.querySelector('.modal-form-block')) {
    new ModalFormBlock(document.querySelector('.modal-form-block')).init();
}

if (document.querySelector('.map-block')) {
    new Map(document.querySelector('.map-block')).init();
}

if (document.querySelector('#product-calc')) {
    new ProductCalc(document.querySelector('#product-calc')).init();
}

if (document.querySelector('.accordion')) {
    document.querySelectorAll('.accordion').forEach(el => {
        new Accordion(el).init();
    })
}

if (document.querySelector('.modal')) {
    document.querySelectorAll('.modal').forEach(el => {
        new Modal(el).init();
    })
}

if (document.querySelector('.input')) {
    document.querySelectorAll('.input').forEach(el => {
        new Input(el).init();
    })
}

if(document.querySelector('.js-form-cooperation')) {
    Forms(document.querySelector(".js-form-cooperation"), {
        resultContainer: ".request-result",
        resultSelector: ".request-result",
    });
}

if(document.querySelector('.js-form-have-questions')) {

    Forms(document.querySelector(".js-form-have-questions"), {
        resultContainer: ".request-result",
        resultSelector: ".request-result",
    });
    // Forms(docu)
}

if(document.querySelector('#calc-page')) {
    new ProductCalc('#calc-page').init();
}

//*****************************************************************
//               remove comment to support pwa

// import runtime from 'serviceworker-webpack-plugin/lib/runtime';
//
// if ('serviceWorker' in navigator) {
//     const registration = runtime.register();
// }

//*****************************************************************