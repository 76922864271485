export default function() {
  const isEdge = /edge/i.test(window.navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isChrome = !!window.chrome && !isOpera && !isEdge;
  const isIE= typeof document !== 'undefined' && !!document.documentMode && !isEdge;
  const isFirefox = typeof window.InstallTrigger !== 'undefined';


  if (isEdge) document.body.classList.add('browser-is-Edge');
  if (isSafari) document.body.classList.add('browser-is-Safari');
  if (isOpera) document.body.classList.add('browser-is-Opera');
  if (isChrome) document.body.classList.add('browser-is-Chrome');
  if (isIE) document.body.classList.add('browser-is-IE');
  if (isFirefox) document.body.classList.add('browser-is-Firefox');
}