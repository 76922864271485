var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "place-order-block" }, [
    _c("div", { staticClass: "place-order-block__param-row" }, [
      _c("div", { staticClass: "place-order-block__param-title" }, [
        _vm._v("Количество, уп.")
      ]),
      _c("div", { staticClass: "place-order-block__number-wrapper" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.count,
              expression: "count"
            }
          ],
          staticClass: "input input_input",
          attrs: {
            placeholder: "",
            type: "number",
            max: "50",
            min: "1",
            name: "count"
          },
          domProps: { value: _vm.count },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.count = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }