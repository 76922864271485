import Inputmask from 'inputmask';

export default class Input {
    constructor(el) {
        this.block = el;
        this.blockName = 'input';
        this.contentName = `${this.blockName}__wrapper`;
        this.mask = null;
        this.type = 'text';
        this.mods = {
            error: `${this.blockName}_error`,
            success: `${this.blockName}_success`,
            filled: `${this.blockName}_filled`,
            focus: `${this.blockName}_focus`,
        };
        this.content = this.block.querySelector(`.${this.contentName}`);
        this.field = this.block.querySelector(`.${this.blockName}__area`);
        this.init();
    }

    init() {
        this.setFieldType();
        this.maskInput();
        this.addEvents();
        // this.checkInputFilled();
    }

    setFieldType() {
        const type = this.block.getAttribute('data-type');
        if (!type) {
            return;
        }
        this.type = type;
    }

    addEvents() {
        this.onFocus();
        this.onBlur();
        this.onInput();
    }

    validateEmail(email) {
        var re = /^(?!.*@.*@.*$)(?!.*@.*\-\-.*\..*$)(?!.*@.*\-\..*$)(?!.*@.*\-$)(.*@.+(\..{1,11})?)$/;
        return re.test(String(email).toLowerCase());
    }

    validateName(name) {
        // if (!this.field.value.length) {
        if (!name.length) {
            this.block.classList.add(this.mods.error)
        }
    }

    validateInn(inn) {
        // if (!this.field.value.length) {
        if (inn.length > 12 || inn.length < 10) {
            console.log(inn.length);
            this.block.classList.add(this.mods.error)
        }
    }

    checkInputFilled() {
        if (this.field.value) {
            this.block.classList.add(this.mods.filled);
        }
    }

    maskInput() {
        switch (this.type) {
            case 'phone':
                Inputmask({
                    regex: '^\\+7 \[0-9]{3}\ [0-9]{3}-[0-9]{2}-[0-9]{2}$',
                    placeholder: '+7 ___ ___-__-__',
                    showMaskOnHover: false,
                    greedy: false,
                   
                }).mask(this.block);
                break;
            case 'email':
                // this.block.oninput = () => {
                //     if (this.validateEmail(this.block.value)) {
                //         this.content.classList.add(this.mods.success);
                //     } else {
                //         this.content.classList.remove(this.mods.success);
                //         this.content.classList.add(this.mods.error);
                //     }
                // };
                // this.block.onblur = () => {
                //     if (this.validateEmail(this.block.value)) {
                //         this.content.classList.add(this.mods.success);
                //     } else {

                //         this.content.classList.remove(this.mods.success);
                //         this.content.classList.add(this.mods.error);
                //         if (this.block.value != '') {
                //         }
                //     }
                // };
                // this.block.onfocus = () => {
                //     this.content.classList.remove(this.mods.error);
                // };
                Inputmask({
                    mask: "*{2,40}",
                    greedy: false,
                    showMaskOnHover: false,
                    definitions: {
                        '*': {
                          validator: "[0-9A-Za-z!.#@'*+/^_~\-]",
                          casing: "lower"
                        }
                      }
                }).mask(this.block);
            case 'name': 
                this.block.onblur = () => {
                    this.validateName(this.block.value);
                }
                break;
            case 'inn': 
                Inputmask({
                    mask: '9999999999[99]',
                    clearMaskOnLostFocus: true,
                    greedy: false,
                    oncomplete: () => {
                        this.block.classList.add(this.mods.success);
                    },
                    onincomplete: () => {
                        this.block.classList.remove(this.mods.success);
                        this.block.classList.add(this.mods.error);
                    },
                }).mask(this.block);
                break;
        }
    }

    onFocus() {
        this.block.addEventListener('focus', () => {
            this.block.classList.add(this.mods.focus);
        })
    }

    onBlur() {
        this.block.addEventListener('blur', (e) => {
            if (e.target.value.length) {
                this.block.classList.add(this.mods.filled);
            } else {
                this.block.classList.remove(this.mods.filled);
            }
            this.block.classList.remove(this.mods.focus);
        })
    }

    onInput() {
        this.block.addEventListener('input', (e) => {
            if (e.target.value.length) {
                this.block.classList.add(this.mods.filled);
                this.block.classList.remove(this.mods.error);
            }
        })
    }

    blur() {
        if(!this.block.value && this.block.required) {
            this.block.classList.add(this.mods.error);
        } else {
            this.block.classList.remove(this.mods.error);
        }
    }

    showSuccess() {
        this.block.classList.remove(this.mods.error);
        if (this.block.value.length) {
            this.block.classList.add(this.mods.filled);
        } else {
            this.block.classList.remove(this.mods.filled);
        }
        this.block.classList.add(this.mods.success);
    }

    showError() {
        this.block.classList.remove(this.mods.success);
        if (this.block.value.length) {
            this.block.classList.add(this.mods.filled);
        } else {
            this.block.classList.remove(this.mods.filled);
        }
        this.block.classList.add(this.mods.error);
    }
}
