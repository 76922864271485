import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import { store } from './store/store.tsx';

export default class ProductCalc {
    constructor(el) {
        this.el = el;
    }
    init() {
        new Vue({
            render: h => h(App),
            store: store,
        }).$mount(this.el)
    }
}