import Axios from "axios";

export default class FaqForm {
	constructor(el) {
		this.block = el;
		this.form = el.querySelector('form');
		this.submitButton = el.querySelector('.button_submit');
		this.url = this.form.dataset.url;
	}

	init() {
		// this.submitButtonHandler();
		// console.log(this.url);
	}

	sendForm(form) {
		let data = new FormData(form);
		Axios.post(this.url, data);
	}

	submitForm(form) {
		let inputsArr = form.querySelectorAll('input[required]');

		if (inputsArr.length) {
			let isValid = true;

			inputsArr.forEach(el => {
				if (el.value == '') {
					isValid = false;
				}
			});

			return isValid;
		}
	}

	submitButtonHandler() {
		this.submitButton.addEventListener('click', e => {
			e.preventDefault();

			if (this.submitForm(this.form)) {
				this.sendForm(this.form);
			} else {
				console.log('error');
			}
		})
	}
}