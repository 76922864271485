var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "place-order-block" }, [
      _c("h1", { staticClass: "title title_medium title_black" }, [
        _vm._v("Оформить заказ")
      ]),
      _c(
        "div",
        {
          staticClass: "place-order-block__wrapper",
          attrs: { id: "product-calc" }
        },
        [
          _c(
            "div",
            { staticClass: "place-order-block__top" },
            [
              _vm.options
                ? _c(
                    "div",
                    { staticClass: "place-order-block__start" },
                    [
                      _c("BoardTitle", {
                        attrs: { title: _vm.singleBoard.translate }
                      }),
                      _c("SelectionType", {
                        attrs: {
                          data: _vm.options["selections"],
                          "single-board": _vm.singleBoard,
                          mobile: _vm.mobile
                        }
                      }),
                      _c("MethodsType", {
                        attrs: {
                          data: _vm.options["processing_method"],
                          "single-board": _vm.singleBoard,
                          mobile: _vm.mobile
                        }
                      }),
                      _c("CoversType", {
                        attrs: {
                          data: _vm.options["coating_type"],
                          "single-board": _vm.singleBoard,
                          mobile: _vm.mobile
                        }
                      }),
                      _c("Count")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "client"
                ? _c("SingleBoard", { attrs: { board: _vm.singleBoard } })
                : _vm._e(),
              _vm.type === "admin"
                ? _c("FullBoards", {
                    attrs: {
                      boards: _vm.boards,
                      "single-board": _vm.singleBoard
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.type === "admin"
            ? _c("FooterAdmin", { attrs: { "total-price": _vm.totalPrice } })
            : _vm._e(),
          _vm.type === "client"
            ? _c("FooterClient", { attrs: { "total-price": _vm.totalPrice } })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }