import Plugin from "../core/Plugin";
import init from "../core/init";
import validate from "validate.js";

class Validation extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {
    this.errorsShowed = false;
    this.updateValidation = this.updateValidation.bind(this);
    validate.formatters.brand = function (errors) {
      let new_errors = {};
      Object.keys(errors).forEach((key) => {
        let error = errors[key];
        new_errors[error.attribute] = [error.options.message];
        // new_errors[key] = err;
      });
      return new_errors;
    };
  }

  bindEvents() {
    this.controls = this.element.querySelectorAll("input, textarea, select");
    this.constraints = this.buildConstraints();
  }

  buildConstraints() {
    let result = {};
    this.controls.forEach((el) => {
      let item = (result[el.getAttribute("name")] = {});
      if (
        el.getAttribute("required") !== undefined &&
        el.getAttribute("required") !== null
      ) {
        item.presence = { message: "Обязательное поле" };
      }

      if (el.getAttribute("data-validate") === "email") {
        item.email = { message: "Некорректный Email" };
      }

      if (el.getAttribute("data-validate") === "string") {
        item.format = {
          pattern: "^[a-zA-Zа-яА-Я]+$",
          message: "Некорректный формат",
        };
      }

      if (el.getAttribute("data-validate") === "number") {
        item.format = {
          pattern: "^[0-9]+$",
          message: "Допустимы только цифры",
        };
      }

      if (
        el.getAttribute("data-min") &&
        el.getAttribute("data-min") > el.value
      ) {
        item.presence = {
          message: `Минимальное количество символов ${el.getAttribute(
            "data-min"
          )}`,
        };
      }

      if (el.getAttribute("data-validate") === "phone") {
        item.format = {
          pattern: "^\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}\\-[0-9]{2}\\-[0-9]{2}",
          message: "Некорректный телефон",
        };
      }
    });

    return result;
  }

  validate() {
    return new Promise((resolve, reject) => {
      let errors = validate(this.element, this.constraints, {
        format: "brand",
      });
      this.showErrors(errors || {});
      if (!errors) resolve();
      else reject();
    });
  }

  showErrors(errors) {
    this.resetErrors();
    this.controls.forEach((el) => {
      this.showErrorForControl(el, errors);
    });
  }

  showErrorForControl(control, errors) {
    let parent = control.parentNode;
    Object.keys(errors).forEach((el) => {
      if (el == control.getAttribute("name")) {
        parent.classList.add("has-error");
        let error = this.addError(errors[el]);
        parent.insertBefore(error, control);
        this.errorsShowed = true;
        control.addEventListener("change", this.updateValidation);
      }
    });
  }

  updateValidation(e) {
    let errors = validate(this.element, this.constraints, { format: "brand" });
    this.showErrors(errors || {});
  }

  addError(text) {
    let error = document.createElement("span");
    error.classList.add("input__error");
    error.innerText = text;

    return error;
  }

  resetErrors() {
    this.controls.forEach((el) => {
      let parent = el.parentNode;
      el.removeEventListener("change", this.updateValidation);
      parent.classList.remove("has-error");
      let error = parent.querySelector(".input__error");
      if (error) error.remove();
    });
  }
}

export default init(Validation, "validation");
