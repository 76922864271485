export default class Menu {
	constructor(el) {
		this.block = el;
		this.menuButton = el.querySelector('.menu__menu-button');
		this.desktopMenu = el.querySelector('.menu__list');
		this.mobileMenu = el.querySelector('.menu__mobile-menu');
		this.mobileCloseButton = el.querySelector('.menu__close-button');
		this.humberger = el.querySelector('.menu-humberger');
		this.deviceWidth;
	}

	init() {
		this.deviceWidth = window.innerWidth;
		this.menuToggleButtonHandler();
		this.mobileCloseButtonHandler();
		this.humbergerAction();
	}

	desktopMenuToggle() {
		this.desktopMenu.classList.toggle('js_active');
	}

	mobileMenuOpen() {
		this.mobileMenu.classList.add('js_active');
	}

	mobileMenuClose() {
		if(window.innerWidth < 576) {
			this.toggleBodyVisible()
		}
		this.mobileMenu.classList.remove('js_active');
	}

	humbergerAction() {

		this.humberger.addEventListener('click', () => {
			if(window.innerWidth > 576) {
				this.humberger.classList.toggle('humberger-active')
			}
			if(window.innerWidth < 576) {
				this.toggleBodyVisible()
			}
			this.mobileMenu.classList.toggle('menu__mobile-menu_active')
		})
	}

	toggleBodyVisible() {
		if(document.body.style.overflowY === 'hidden') {
			document.body.style.overflowY = 'scroll'
		}
		else {
			document.body.style.overflowY = 'hidden'
		}
	}

	menuToggleButtonHandler() {
		this.menuButton.addEventListener('click', () => {
			if (!this.mobileMenu.classList.contains('js_active')) {
				this.mobileMenuOpen();
			} else {
				this.mobileMenuClose();
			}
		})
	}

	mobileCloseButtonHandler() {
		this.mobileCloseButton.addEventListener('click', () => {
			this.mobileMenuClose();
			this.mobileMenu.classList.toggle('menu__mobile-menu_active');
		})
	}
}