export default class Accordion {
  constructor(el) {
      this.block = el;
      this.title = el.querySelector('.accordion__head');
      this.content = el.querySelector('.accordion__content');
  }

  init() {
    this.accordionSetHeightHandler();
    this.accordionOpenCloseHandler();
  }

  accordionSetHeightHandler() {
    let blockHeight = this.content.offsetHeight;
    this.content.style.maxHeight = blockHeight + 'px';
    this.content.closest('.accordion').classList.add('js_closed');
  }

  accordionOpenCloseHandler() {
    this.title.addEventListener('click', () => {
      if (this.block.classList.contains('js_closed')) {
        this.block.classList.remove('js_closed');
        this.block.classList.add('js_opened');
      } else {
        this.block.classList.remove('js_opened');
        this.block.classList.add('js_closed');
      }
    })
  }
}